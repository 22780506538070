import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { StudioSettingsContext } from './Context';

const getDefaultHeight = () => window.innerHeight - 165;

const defaultValue = {
	value: {
		label: 'Default',
		leftMenu: { favoriteTab: '', isOpen: false, isCollapsed: false, x: 0, y: 64 },
		rightMenu: { favoriteTab: 'people', isOpen: false, isCollapsed: false, x: 0, y: 64 },
		midRightMenu: { favoriteTab: '', isOpen: false, isCollapsed: false, x: 0, y: 64 },
		peopleMenu: { favoriteTab: '', isOpen: false, isExpandable: true, isExpanded: false, x: 0, y: 64, width: 440, height: getDefaultHeight() },
		inviteMenu: { favoriteTab: '', isOpen: false, isExpandable: true, isExpanded: false, x: 0, y: 64, width: 440, height: getDefaultHeight() },
		emailShareMenu: { favoriteTab: '', isOpen: false, isExpandable: true, isExpanded: false, x: 0, y: 64, width: 440, height: getDefaultHeight() },
		smsShareMenu: { favoriteTab: '', isOpen: false, isExpandable: true, x: 0, y: 64, width: 440, height: getDefaultHeight() },
		linkShareMenu: { favoriteTab: '', isOpen: false, isExpandable: true, x: 0, y: 64, width: 440, height: getDefaultHeight() },
		groupsShareMenu: { favoriteTab: '', isOpen: false, isExpandable: true, isExpanded: false, x: 0, y: 64, width: 440, height: getDefaultHeight() },
		qrShareMenu: { favoriteTab: '', isOpen: false, isExpandable: true, isExpanded: false, x: 0, y: 64, width: 440, height: getDefaultHeight() },
		pollMenu: { favoriteTab: '', isOpen: false, isExpandable: true, isExpanded: false, x: 0, y: 64, width: 440, height: getDefaultHeight() },
		auctionMenu: { favoriteTab: '', isOpen: false, isExpandable: true, isExpanded: false, x: 0, y: 64, width: 440, height: getDefaultHeight() },
		drawMenu: { favoriteTab: '', isOpen: false, drawMenu: true, isExpandable: true, isExpanded: false, x: 0, y: 64, width: 440, height: getDefaultHeight() },
		toolsMenu: { favoriteTab: '', isOpen: false, isExpandable: true, isExpanded: false, x: 0, y: 64, width: 440, height: getDefaultHeight() },
		elementsMenu: { favoriteTab: '', isOpen: false, isExpandable: true, isExpanded: false, x: 0, y: 64, width: 440, height: getDefaultHeight() },
		libraryVideosMenu: { favoriteTab: '', isOpen: false, isExpandable: true, isExpanded: false, x: 0, y: 64, width: 440, height: getDefaultHeight() },
		libraryAudiosMenu: { favoriteTab: '', isOpen: false, isExpandable: true, isExpanded: false, x: 0, y: 64, width: 440, height: getDefaultHeight() },
		libraryGFXMenu: { favoriteTab: '', isOpen: false, isExpandable: true, isExpanded: false, x: 0, y: 64, width: 440, height: getDefaultHeight() },
		libraryImagesMenu: { favoriteTab: '', isOpen: false, isExpandable: true, isExpanded: false, x: 0, y: 64, width: 440, height: getDefaultHeight() },
		libraryPlaylistMenu: { favoriteTab: '', isOpen: false, isExpandable: true, isExpanded: false, x: 0, y: 64, width: 440, height: getDefaultHeight() },
		streamsMenu: { favoriteTab: '', isOpen: false, isExpandable: true, isExpanded: false, x: 0, y: 64, width: 440, height: getDefaultHeight() },
		chatMenu: { favoriteTab: '', isOpen: false, chatMenu: true, isExpandable: true, isExpanded: false, x: 0, y: 64, width: 440, height: (getDefaultHeight() + 47) },
		mediaManager: { favoriteTab: '', isOpen: false, isExpandable: true, isExpanded: false, x: 0, y: 64, width: 440, height: getDefaultHeight() },
		beeyouUsersMenu: { favoriteTab: '', isOpen: false, isExpandable: true, isExpanded: false, x: 0, y: 64, width: 440, height: getDefaultHeight() },
		scenesMenu: { favoriteTab: '', isOpen: false, isExpandable: true, isExpanded: false, x: 0, y: 64, width: 440, height: getDefaultHeight() },
		participantDrawMenu: { favoriteTab: '', isOpen: false, participantDrawMenu: true, x: 0, y: 64, width: 440, height: getDefaultHeight() },
		peopleMenuWithInvite: { favoriteTab: '', isOpen: false, fromHeader: true, isExpandable: true, isExpanded: false, x: 0, y: 64, width: 440, height: getDefaultHeight() },
	},
};

export const fullScreenMenuItems = Object.keys(defaultValue.value).filter((key) => key !== 'label'
  && key !== 'leftMenu'
  && key !== 'rightMenu'
  && key !== 'midRightMenu');

export const StudioSettingsProvider = ({ children }) => {
	const savedValue = useMemo(() => (
		localStorage.getItem('studioSettings') ? { value: JSON.parse(localStorage.getItem('studioSettings')) } : defaultValue
	), []);
	const [studioSettings, setStudioSettings] = useState(savedValue);
	const [previousOpenMenu, setPreviousOpenMenu] = useState(null);

	useEffect(() => {
		const handleResize = () => {
			const newHeight = getDefaultHeight();
			setStudioSettings((prev) => ({
				value: Object.keys(prev.value).reduce((acc, key) => {
					if (prev.value[key].x === 0 && prev.value[key].y === 64) {
						acc[key] = {
							...prev.value[key],
							height: key === 'chatMenu' ? newHeight + 50 : newHeight,
						};
					} else {
						acc[key] = prev.value[key];
					}
					return acc;
				}, {}),
			}));
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const context = useMemo(() => {
		const isMenuOpened = fullScreenMenuItems.some(
			(menuKey) => studioSettings.value[menuKey]?.isOpen,
		);

		const activeMenu = fullScreenMenuItems.find(
			(menuKey) => studioSettings.value[menuKey]?.isOpen,
		);

		const isMenuExpanded = activeMenu ? studioSettings.value[activeMenu]?.isExpanded : false;
		return {
			handleChangeStudioSettings: (data) => {
				setStudioSettings({
					value: {
						...data,
					},
				});
				localStorage.setItem('studioSettings', JSON.stringify(data));
			},
			handleMenuPosition: (menuKey, data) => {
				setStudioSettings((prev) => ({
					...prev,
					value: {
						...prev.value,
						[menuKey]: {
							...prev.value[menuKey],
							...data,
						},
					},
				}));
			},
			handleChangeRightMenu: (data) => {
				setStudioSettings((prev) => ({
					...prev,
					value: {
						...prev.value,
						rightMenu: {
							...prev.value.rightMenu,
							...data,
						},
					},
				}));
			},
			handleChangeMidRightMenu: (data) => {
				setStudioSettings((prev) => ({
					...prev,
					value: {
						...prev.value,
						midRightMenu: {
							...prev.value.midRightMenu,
							...data,
						},
					},
				}));
			},
			handleChangeLeftMenu: (data) => {
				setStudioSettings((prev) => ({
					...prev,
					value: {
						...prev.value,
						leftMenu: {
							...prev.value.leftMenu,
							...data,
						},
					},
				}));
			},
			handleExpandMenu: (menuKey) => {
				setStudioSettings((prev) => ({
					...prev,
					value: {
						...prev.value,
						[menuKey]: {
							...prev.value[menuKey],
							isExpanded: !prev.value[menuKey]?.isExpanded,
						},
					},
				}));
			},
			handleChangeMenuItem: (menuKey, data) => {
				if (data.isOpen) {
					const currentOpenMenu = fullScreenMenuItems.find(
						(key) => studioSettings.value[key]?.isOpen,
					);
					setPreviousOpenMenu(currentOpenMenu || null);
				} else {
					setPreviousOpenMenu(null);
				}
				setStudioSettings((prev) => ({
					...prev,
					value: {
						...prev.value,
						...(fullScreenMenuItems.includes(menuKey)
							? fullScreenMenuItems.reduce((acc, key) => ({
								...acc,
								[key]: key === menuKey
									? {
										...prev.value[key],
										...data,
										isOpen: data.isOpen !== undefined
											? data.isOpen
											: !prev.value[key].isOpen,
									}
									: {
										...prev.value[key],
										// eslint-disable-next-line no-nested-ternary
										isOpen: window.innerWidth >= 1200
											? (prev.value[key].x === 0 && prev.value[key].y === 64
												? false
												: prev.value[key].isOpen)
											: false,
									},
							}), {})
							: { [menuKey]: { ...prev.value[menuKey], ...data } }
						),
					},
				}));
			},
			studioSettings,
			defaultValue,
			isMenuOpened,
			previousOpenMenu,
			isMenuExpanded,
		};
	}, [studioSettings, previousOpenMenu]);

	return (
		<StudioSettingsContext.Provider value={context}>
			{children}
		</StudioSettingsContext.Provider>
	);
};

StudioSettingsProvider.propTypes = {
	children: PropTypes.node,
};

StudioSettingsProvider.defaultProps = {
	children: undefined,
};
